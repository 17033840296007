<template>
  <aside class="g-taxonomy">
    <div class="bb-container">
      <div class="g-taxonomy__h">Categorías</div>
      <div class="g-taxonomy__items">
        <nav class="g-taxonomy__item" v-for="category in categories" :key="category.id">
          <div class="g-taxonomy__title">
            <a :href="categoryLink(category)" @click.prevent="gotoCategory(category)">
              {{ category.name }}
            </a>
          </div>
          <div class="g-taxonomy__nav">
            <div
              class="g-taxonomy__category"
              v-for="(subCategory, index) in category.subCategory"
              :key="index"
            >
              <a
                :href="categoryLink(subCategory)"
                @click.prevent="gotoCategory(subCategory)"
              >
                {{ subCategory.name }}
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryLink from "./mixins/CategoryLink";
import GotoCategory from "./mixins/GotoCategory";

export default {
  name: "CategoriesDirectory",
  mixins: [CategoryLink, GotoCategory],
  computed: {
    ...mapGetters({
      categories: "categories",
    }),
  },
  mounted() {
    if (this.categories === null || this.categories.length === 0) {
      this.$store.dispatch("getCategories", false);
    }
  },
};
</script>
